module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"K note.dev","titleTemplate":"%s | K note.dev","defaultTitle":"K note.dev","description":"K's notes. Output programming, personal projects, and everyday things.","language":"ja","canonical":"https://knote.dev","htmlAttributes":{"prefix":"og: https://ogp.me/ns#"},"openGraph":{"type":"website","locale":"ja_JP","url":"https://knote.dev","site_name":"K note.dev","title":"K note.dev","description":"K's notes. Output programming, personal projects, and everyday things.","images":[{"url":"https://knote.dev/ogp.png"}]},"twitter":{"site":"@k_urtica","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"quality":90,"withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"K note.dev","short_name":"K note.dev","description":"K's notes. Output programming, personal projects, and everyday things.","start_url":"/","background_color":"#fafafa","theme_color":"#1e3a8a","display":"standalone","lang":"ja","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"398097a99dda7b395ccf2a5ab9bfb171"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-W8WC213W4R"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
